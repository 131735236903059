<template>
  <v-container>
    <v-progress-linear
      v-if="page < totalCount"
      v-model="percent"
      height="19"
    >
      <strong>{{ Math.ceil(percent) }}%</strong>
    </v-progress-linear>

    <v-row justify="space-around">
      <v-col
        v-if="page < totalCount"
        cols="12"
        class="text-h5 mt-8 text-center"
      >
        {{ qqq[page].q }}
      </v-col>
    </v-row>

    <!-- 여러개 선택 -->
    <v-row
      v-show="lastPage == false"
      v-if="page < totalCount && selectMany"
      justify="space-around"
    >
      <v-col
        v-for="(choice, index) in qqq[page].choicelist"
        :key="index"
        cols="12"
        lg="12"
      >
        <v-btn
          block
          @click="nextPage(choice.choicePoint)"
        >
          {{ choice.choiceText }}
        </v-btn>
      </v-col>
    </v-row>
    <!-- 여러개중 선택 END-->
    <!-- 2개중 선택 -->
    <v-row
      v-show="lastPage == false"
      v-if="page < totalCount && selectTwo"
      justify="space-around"
    >
      <v-col
        cols="12"
        lg="6"
      >
        <v-img
          :src="src1"
          aspect-ratio="2"
          gradient="to top right, rgba(255,255,255,.6), rgba(255,255,255,.8)"
          @click="nextPage(1)"
        >
          <template>
            <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
            >
              <v-card-text class="text-h2 text-center">
                {{ y }}
              </v-card-text>
            </v-row>
          </template>
        </v-img>
      </v-col>

      <v-col
        cols="12"
        lg="6"
      >
        <!-- <div class="subheading">
          Too high
        </div> -->
        <v-img
          :src="src2"
          aspect-ratio="2"
          gradient="to top right, rgba(255,255,255,.6), rgba(255,255,255,.8)"
          @click="nextPage(-1)"
        >
          <template>
            <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
            >
              <v-card-text class="text-h2 text-center">
                {{ n }}
              </v-card-text>
            </v-row>
          </template>
        </v-img>
      </v-col>
    </v-row>
    <!-- 2개중 선택 END-->
    <v-row
      v-show="lastPage"
      justify="space-around"
    >
      <v-container>
        <v-row
          v-show="isWating"
          justify="space-around"
        >
          <v-col
            class="align-content"
            lg="6"
            md="6"
            sm="8"
          >
            <v-img :src="require('@/assets/articles/loadload.gif')" />
          </v-col>
        </v-row>
        <!-- <v-skeleton-loader
          v-bind="attrs"
          type="card, paragraph,
          text,text,text,sentences,
          text,text,sentences
           "
        /> -->
        <div class="text-center">
          <v-dialog
            v-model="dialog"
            persistent
            width="400"
          >
            <v-card>
              <v-alert
                class="text-center"
                type="success"
                style="margin-bottom: 0px"
              >
                <strong>테스트 결과가 나왔습니다!</strong>
              </v-alert>
            </v-card>
          </v-dialog>
        </div>
      </v-container>
    </v-row>
  </v-container>
</template>

<script>
import { mapMutations } from 'vuex'
import resulttype from '@/data/resulttype'
export default {
  components: {},
  props: {
    themeId: String,
    qqq: Array,
    totalCount: Number,
  },
  data() {
    return {
      page: 0,
      lastPage: false,
      percent: 0,
      ie: 0,
      ns: 0,
      tf: 0,
      pj: 0,
      mbti: '',
      y: '',
      n: '',
      score: 0,
      src1: '',
      src2: '',
      attrs: {
        class: 'mb-6',
        boilerplate: true,
      },
      dialog: false,
      resultPath: '',
      selectKey: 0,
      isWating: true,
      selectTwo: false,
      selectMany: false,
      qid: '',
    }
  },
  updated() {
    this.percent = (this.page / this.totalCount) * 100
    // this.setAnswer()
  },
  beforeDestroy() {
    this.page = 0
    this.lastPage = false
    this.percent = 0
    this.ie = 0
    this.ns = 0
    this.tf = 0
    this.pj = 0
    this.mbti = ''
    this.y = ''
    this.n = ''
    this.score = 0
  },
  mounted() {
    this.setLoading(false)
    this.setAnswer()
  },
  methods: {
    ...mapMutations('common', ['setLoading']),
    setAnswer() {
      this.selectMany = false
      this.selectTwo = false

      if (!(this.page < this.totalCount)) return false
      this.selectKey = this.qqq[this.page].selectKey

      resulttype.selectValue.map(item => {
        if (this.selectKey == item.key) {
          this.y = item.y
          this.n = item.n
        }
      })

      if (this.selectKey == '0') {
        this.selectMany = true
      } else if (this.selectKey == 1) {
        this.selectTwo = true
        this.src1 =
          'https://firebasestorage.googleapis.com/v0/b/tryme-eeb82.appspot.com/o/1.jpg?alt=media&token=e00c4a5c-c14c-4204-845f-9adf17b591e0'
        this.src2 = 'https://picsum.photos/515/300?random'
      } else {
        this.selectTwo = true
        this.src1 =
          'https://firebasestorage.googleapis.com/v0/b/tryme-eeb82.appspot.com/o/ye.png?alt=media&token=275b7fae-e902-430a-88ac-deabe88653fa'
        this.src2 =
          'https://firebasestorage.googleapis.com/v0/b/tryme-eeb82.appspot.com/o/no.jpg?alt=media&token=3120f076-118e-42ff-9395-3c8e92426146'
      }
    },
    nextPage(val) {
      this.setAnswer()
      if (this.qqq[this.page].typeOf == 'OX') {
        if (
          (this.qqq[this.page].OorX == 'O' && val == 1) ||
          (this.qqq[this.page].OorX == 'X' && val == '-1')
        ) {
          this.score++
        }
      } else if (this.qqq[this.page].typeOf === 'IE') this.ie = this.ie + val
      else if (this.qqq[this.page].typeOf === 'NS') this.ns = this.ns + val
      else if (this.qqq[this.page].typeOf === 'TF') this.tf = this.tf + val
      else if (this.qqq[this.page].typeOf === 'PJ') this.pj = this.pj + val
      else {
        // OX 퀴즈나 MBTI 형식이 아닌것
        this.score += Number(val)
        // console.log('SCORE:', this.score)
      }
      // console.log("-> "this.ie, this.ns, this.tf, this.pj)

      if (this.page < this.totalCount) {
        this.page = this.page + 1
      }
      if (this.page === this.totalCount) {
        this.setLoading(true)
        this.lastPage = true
        this.getResult()
      }
    },
    getResult() {
      if (this.ie >= 0) this.mbti += 'I'
      else this.mbti += 'E'
      if (this.ns > 0) this.mbti += 'N'
      else this.mbti += 'S'
      if (this.tf >= 0) this.mbti += 'T'
      else this.mbti += 'F'
      if (this.pj > 0) this.mbti += 'P'
      else this.mbti += 'J'

      // console.log('-> ', this.mbti)
      setTimeout(() => {
        this.isWating = false
        this.dialog = true
        this.goToResult()
      }, 1600)
    },
    goToResult() {
      setTimeout(() => {
        this.$store.dispatch('qna/updateCount', this.themeId)
        if (this.score > 0) {
          this.resultPath = '/result/' + this.themeId + '/' + this.score
        } else {
          this.resultPath = '/result/' + this.themeId + '/' + this.mbti
        }
        this.$router.push(this.resultPath)
      }, 2500)
    },
  },
}
</script>

<style></style>
